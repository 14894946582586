.p-about__message {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  gap: 40px;
  @include mq(u-pc) {
    flex-direction: column;
    margin-top: 40px;
  }
}

.p-about__message__img {
  width: 360px;
  border: 1px solid #888888;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
  @include mq(u-pc) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
}

.p-about__message__content {
  max-width: 424px;
  @include mq(u-pc) {
    max-width: 100%;
  }
}

.p-about__message__heading {
  font-weight: bold;
  font-size: 28px;
  line-height: 160%;
  letter-spacing: 0.05em;
}

.p-about__message__text,
.p-about__message__senders {
  margin-top: 20px;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
}

.p-about__message__senders {
  margin-top: 40px;
  text-align: end;
}

.p-about__employee {
  margin-top: 80px;
}

.p-about__employee__heading {
  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.05em;
}

.p-about__employee__grid {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px 80px;
  padding: 0 60px;
  @include mq(u-pc) {
    padding: 0;
    gap: 32px;
    margin-top: 40px;
  }
}

.p-about__employee__item {
  width: calc(50% - 40px);
  @include mq(u-pc) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
}

.p-about__employee__img {
  border: 1px solid #888888;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
}

.p-about__employee__name {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
}

.p-about__list {
  margin-top: 60px;
}
