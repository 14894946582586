.p-menu-nav {
  display: flex;
  justify-content: center;
  gap: 12px;
  white-space: nowrap;
  @include mq(u-pc) {
    justify-content: left;
    gap: 20px;
    border-bottom: 2px solid #e7e7e7;
    width: 100%;
    overflow: scroll;
    padding:0 15px;
  }
  @include mq(u-sp) {
    width: 100%;
    // min-width: 768px;
  }
}

.p-menu__container {
  // margin-top: 40px;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  gap: 80px;
  flex-direction: column;
  padding: 0 40px;
  @include mq(u-pc) {
    gap: 60px;
    margin-top: 20px;
    padding: 0 15px;
  }
}

.p-menu__unit {
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 40px;
  @include mq(u-pc) {
    border-radius: 8px;
    padding: 0;
    background-color: unset;
  }
}

.p-menu__unit__heading {
  padding-left: 60px;
  font-weight: bold;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.05em;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    background-size: contain;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &[data-menu="limited"]::before {
    background-image: url("../images/icon/img_icon_limited.png");
  }
  &[data-menu="ac"]::before {
    background-image: url("../images/icon/img_icon_ac.png");
  }
  &[data-menu="kitchen"]::before {
    background-image: url("../images/icon/img_icon_kitchen.png");
  }
  &[data-menu="toilet"]::before {
    background-image: url("../images/icon/img_icon_toilet.png");
  }
  &[data-menu="floor"]::before {
    background-image: url("../images/icon/img_icon_floor.png");
  }
  &[data-menu="washroom"]::before {
    background-image: url("../images/icon/img_icon_washroom.png");
  }
  &[data-menu="bathroom"]::before {
    background-image: url("../images/icon/img_icon_bathroom.png");
  }
  &[data-menu="full_cleaning"]::before {
    background-image: url("../images/icon/img_icon_full_cleaning.png");
  }
  &[data-menu="window"]::before {
    background-image: url("../images/icon/img_icon_window.png");
  }
  &[data-menu="others"]::before {
    background-image: url("../images/icon/img_icon_others.png");
  }
  @include mq(u-pc) {
    font-size: 20px;
    padding-left: 34px;
    padding-bottom: 2px;
    &::before {
      width: 28px;
      height: 28px;
    }
  }
  &--single {
    color: $colorTurquoise;
    font-weight: bold;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0.05em;
    @include mq(u-pc) {
      font-size: 20px;
    }
  }
}

.p-menu__unit__column {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @include mq(u-pc) {
    margin-top: 20px;
  }
  @include mq(u-sp) {
    gap: 20px;
  }
}

.p-menu__item {
  display: flex;
  gap: 20px;
  padding: 16px;
  border-radius: 8px;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  @include mq(u-pc) {
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  @include mq(u-sp) {
    overflow: hidden;
    flex-direction: column;
    gap: 12px;
    padding: 0;
  }
}

.p-menu__item__thumbnail {
  width: 330px;
  border-radius: 4px;
  flex-shrink: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  @include mq(u-sp) {
    width: 100%;
  }
}

.p-menu__item__taxonomy__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.p-menu__item__info {
  width: 100%;
  @include mq(u-sp) {
    padding: 0 12px 12px;
  }
}

.p-menu__item__info__label {
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.05em;
  min-height: 96px;
  &:not(:first-child) {
    margin-top: 20px;
  }
  @include mq(u-sp) {
    margin-top: 12px;
    min-height: auto;
  }
}

.p-menu__price__wrap {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  align-items: center;
  @include mq(u-pc) {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    justify-content: unset;
  }
}

.p-menu__price,
.p-menu__discount__price {
  font-weight: bold;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $colorOrange;
  & > span {
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #888;
    margin-left: 4px;
  }
}

.p-menu__item__dummy__btn {
  border-radius: 20px;
  padding: 6px 20px;
  color: #fff;
  background-color: $colorOrange;
  flex-shrink: 0;
  @include mq(u-pc) {
    margin-left: auto;
  }
  @include mq(u-sp) {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
}

.p-menu__discount {
  display: inline-block;
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  @include mq(u-pc) {
    justify-content: flex-end;
  }
}

.p-menu__nomal__price {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.05em;
}

.p-menu__nomal__price {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.05em;
  padding-right: 28px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    height: 2px;
    width: calc(100% - 28px);
    background-color: #eb5454;
    transform: rotateZ(345deg);
    left: 0;
    top: 50%;
  }
  &::after {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-image: url("../images/icon/img_icon_allow_black.png");
    background-size: cover;
  }
}

.p-menu__detail {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  gap: 120px;
  @include mq(u-pc) {
    gap: 80px;
  }
}

.p-menu__detail__subheading {
  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  letter-spacing: 0.05em;
  &:not(:first-child) {
    margin-top: 40px;
  }
}

.p-menu__detail__item {
  & h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0.05em;
    padding: 12px 12px 12px 20px;
    background: rgba(52, 170, 174, 0.1);
    border-left: 8px solid $colorTurquoise;
    @include mq(u-pc) {
      font-size: 22px;
    }
  }
  & h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.05em;
    margin-top: 60px;
    padding-bottom: 8px;
    border-bottom: 4px solid #34aaae;
    @include mq(u-pc) {
      font-size: 18px;
    }
  }
  & > p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.05em;
    @include mq(u-pc) {
      margin-top: 12px;
    }
  }
  & > ul {
    margin-top: 20px;
    padding-left: 32px;
    & > li {
      padding-left: 18px;
      position: relative;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.05em;
      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #c4c4c4;
        border-radius: 50%;
        top: 9px;
        left: 0;
      }
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
    @include mq(u-pc) {
      padding-left: 16px;
    }
  }
}

.p-menu__inquiry__wrap {
  margin-top: 120px;
  padding: 40px 50px;
  background-color: #f4f7f6;
  border-radius: 20px;
}

.p-menu__inquiry {
  display: flex;
  margin-top: 40px;
  gap: 40px;
  justify-content: center;
  &--single {
    margin-top: 40px;
  }
}

.p-menu__inquiry__appeal {
  text-align: center;
  margin-bottom: 12px;
}

.p-menu__detail__table {
  width: 100%;
  max-width: 600px;
  margin: 40px auto 0;
  & td,
  & th {
    text-align: center;
    padding: 8px 0;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.05em;
    border: 1px solid #dddddd;
  }
  & th {
    font-weight: bold;
    background-color: #f5f5f5;
  }
}

.p-menu-breadcrumb {
  margin-top: 60px;
  @include mq(u-pc) {
    margin-top: 20px;
  }
}
