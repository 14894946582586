@font-face {
  font-family: "NotoSansJP";
  font-weight: bold;
  src: url("../fonts/NotoSansJP-Bold.woff");
}
@font-face {
  font-family: "NotoSansJP";
  font-weight: 400;
  src: url("../fonts/NotoSansJP-Regular.woff");
}
@font-face {
  font-family: "ubuntu";
  font-weight: 400;
  src: url("../fonts/Ubuntu-Regular.woff");
}

body {
  font-family: "NotoSansJP";
  //  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;  /* 游ゴシック系 */
  //  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;  /* 游明朝系 */
  //  font-family: Hiragino Sans,"ヒラギノ角ゴシック",Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, "Droid Sans", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;  /* ヒラギノ角ゴ系 */
}
