.p-top__heading {
  font-size: 48px;
  line-height: 100%;
  letter-spacing: 0.1em;
  font-weight: bold;
  @include mq(u-pc) {
    font-size: 28px;
    line-height: 140%;
  }
}

.p-top__slider.is-hover-shadow {
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
}

.p-top__translate {
  margin-top: 20px;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.1em;
  font-family: "ubuntu";
  @include mq(u-pc) {
    font-size: 12px;
    margin-top: 16px;
  }
}

.p-top__content {
  padding-top: 82px;
  @include mq(u-pc) {
    padding: 60px 0 56px;
  }
  @include mq(u-sp) {
    padding: 46px 0 56px;
  }
}

.p-top__menu__section {
  padding: 0 40px;
  margin-top: 120px;
  @include mq(u-pc) {
    padding: 0;
  }
  @include mq(u-sp) {
    margin-top: 80px;
  }
}

.p-top__menu__section__inner {
  width: 100%;
  background-color: #daefd6;
  border-radius: 40px;
  max-width: 1680px;
  margin: 0 auto;
  padding: 120px 0 100px;
  display: flex;
  justify-content: center;
  position: relative;
  @include mq(u-pc) {
    border-radius: 0;
    padding: 80px 0 60px;
  }
}

.p-top__menu__heading, .p-top__price-table__heading {
  width: 100%;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  & > .p-top__heading {
    color: $colorNavy;
  }

  & > .p-top__translate {
    color: $colorTurquoise;
  }
  @include mq(u-pc) {
    top: -16px;
  }
}

.p-top__menu__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 24px;
  max-width: 984px;
  padding: 0 40px;
  @include mq(u-pc) {
    flex-wrap: unset;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    padding: 0 15px;
  }
}

.p-top__menu__item {
  width: calc(33.3% - 16px);
  padding: 6px;
  background-color: #fff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  & > span {
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.05em;
    margin-left: 20px;
  }
  @include mq(u-pc) {
    width: 100%;
    border-radius: 0;
    padding: 6px 15px;
    position: relative;
    & > span {
      font-size: 14px;
    }
    &::after {
      position: absolute;
      content: "";
      width: 24px;
      height: 24px;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      background-image: url("../images/icon/img_icon_arrow_green.png");
      background-size: cover;
    }
  }
}

.p-top__menu__icon {
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
  @include mq(u-pc) {
    width: 60px;
  }
}

.p-column__heading__wrap {
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;
  padding-left: 40px;
  @include mq(u-pc) {
    flex-direction: column;
    margin-left: 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }
}

.p-column__heading {
  font-weight: bold;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: $colorTurquoise;
  writing-mode: vertical-rl;
  @include mq(u-pc) {
    writing-mode: unset;
    font-size: 28px;
  }
}

.p-column__translate {
  font-size: 20px;
  line-height: 100%;
  text-align: right;
  letter-spacing: 0.1em;
  text-align: left;
  color: $colorNavy;
  writing-mode: vertical-rl;
  padding-top: 12px;
  font-family: "ubuntu";
  @include mq(u-pc) {
    padding-top: 0;
    margin-top: 16px;
    font-size: 12px;
    writing-mode: unset;
    text-align: center;
  }
}

.p-top__price-table__section {
  padding: 0 40px;
  margin-top: 160px;
  @include mq(u-pc) {
    padding: 0;
  }
  @include mq(u-sp) {
    margin-top: 80px;
  }
}

.p-top__price-table__section__inner {
  width: 100%;
  background-color: #d6eeef;
  border-radius: 40px;
  max-width: 1680px;
  margin: 0 auto;
  padding: 120px 0 100px;
  display: flex;
  justify-content: center;
  position: relative;
  @include mq(u-pc) {
    border-radius: 0;
    padding: 120px 0 60px;
  }
}

.p-price-table {
  max-width: 984px;
  overflow: hidden;
  table-layout: auto;
  margin: 0 40px;
  border-spacing: 0;
  border: none;
  @include mq(u-pc) {
    margin: 0 15px;
  }
  @include mq(u-sp) {
    display: block;
    margin-inline: auto;
    overflow-x: scroll;
    width: 984px;
    margin: 0;
    padding: 0 15px;
  }
}

.p-price-table table,
.p-price-table th,
.p-price-table td {
    padding: 20px;
}

.p-price-table th {
  background-color: #f4f7f6;
  font-weight: 700;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.p-price-table td {
  background-color: #fff;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.p-price-table tr:first-child th:first-child {
  border-radius: 20px 0 0 0;
}
.p-price-table tr:first-child th:last-child{
  border-radius: 0 20px 0 0;
}
.p-price-table tr:last-child td:first-child{
  border-radius: 0 0 0 20px;
}
.p-price-table tr:last-child td:last-child{
  border-radius: 0 0 20px 0;
}
.p-price-table th:last-child, td:last-child {
  border-right: none;
}

.p-price-table tr:last-child td {
  border-bottom: none;
}

.p-price-table td:first-child {
  min-width: 208px;
} 

.p-price-table td:nth-child(2) {
  max-width: 237px;
  @include mq(u-sp) {
    min-width: 237px;
  }
}

.p-price-table td:nth-child(3) {
  min-width: 106px;
  @include mq(u-sp) {
    min-width: 136px;
  }
}

.p-price-table td:nth-child(4) {
  @include mq(u-sp) {
    min-width: 403px;
  }
}

.p-payment {
  background-color: #fff;
  padding: 80px 40px 80px 120px;
  border-radius: 40px 0px 0px 40px;
  width: 70%;
  min-width: 817px;
  @include mq(u-pc) {
    width: 100%;
    min-width: auto;
    padding: 40px 24px;
    border-radius: 10px;
  }
}

.p-payment__content {
  max-width: 706px;
}

.p-payment__description {
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.1em;
  @include mq(u-pc) {
    font-size: 14px;
    text-align: center;
  }
}

.p-payment__way {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 30px;
  margin-top: 60px;
  @include mq(u-pc) {
    gap: 20px 16px;
    justify-content: center;
    margin-top: 32px;
  }
}

.p-payment__way__item {
  height: 40px;
  & > img {
    width: auto;
    height: 100%;
  }
}

.p-payment__section {
  margin-top: 160px;
  display: flex;
  gap: 60px;
  @include mq(u-pc) {
    flex-direction: column;
    padding: 0 15px;
    gap: 40px;
    margin-top: 80px;
  }
}

.p-flow__section, .p-faq__section {
  margin-top: 94px;
  padding: 0 40px;
  @include mq(u-pc) {
    padding: 0;
  }
}

.p-flow__section__inner, .p-faq__section__inner {
  width: 100%;
  background-color: #daefd6;
  border-radius: 40px;
  max-width: 1680px;
  margin: 0 auto;
  padding: 120px 100px;
  display: flex;
  justify-content: center;
  position: relative;
  @include mq(u-pc) {
    padding: 110px 15px;
    border-radius: 0px 80px;
  }
}

.p-flow {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  @include mq(u-pc) {
    gap: 48px;
  }
}

.p-flow__section__heading, .p-faq__section__heading {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  & > .p-top__heading {
    color: $colorNavy;
  }
  & > .p-top__translate {
    color: $colorTurquoise;
  }
  @include mq(u-pc) {
    width: 100%;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.p-flow__item {
  padding: 40px 60px 40px 100px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
  width: 100%;
  max-width: 984px;
  margin: 0 auto;
  & > figure {
    width: 60px;
    flex-shrink: 0;
  }
  &::after {
    content: attr(data-index);
    position: absolute;
    font-weight: bold;
    font-size: 80px;
    line-height: 100%;
    left: -22px;
    top: 50%;
    transform: translateY(-50%);
    color: $colorTurquoise;
  }
  @include mq(u-pc) {
    max-width: unset;
    flex-direction: column;
    padding: 20px;
    gap: 16px;
    &::after {
      font-size: 60px;
      left: 20px;
      top: 0;
    }
  }
}

.p-flow__item__content {
  & > h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $colorNavy;
  }
  & > p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.05em;
    & > a {
      color: $colorTurquoise;
    }
  }
  @include mq(u-pc) {
    width: 100%;
    & > h3 {
      text-align: center;
    }
    & > p {
      margin-top: 16px;
    }
  }
}

.p-feature__section {
  padding: 160px 0;
  margin-top: 160px;
  display: flex;
  gap: 60px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 43%;
    height: 100%;
    background: #d6eeef;
    border-radius: 0px 200px 200px 0px;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
  }
  @include mq(u-pc) {
    flex-direction: column;
    padding: 80px 0;
    position: relative;
    margin-top: 94px;
    &::after {
      width: calc(100% - 40px);
      border-radius: 0px 40px 40px 0px;
    }
    & .p-column__heading__wrap {
      position: absolute;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  & .p-column__heading {
    color: $colorNavy;
  }
  & .p-column__translate {
    color: $colorTurquoise;
  }
}

.p-feature {
  width: 70%;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @include mq(u-pc) {
    width: 100%;
    padding: 0 0 0 15px;
    gap: 20px;
  }
}

.p-feature__item {
  width: 100%;
  max-width: 1172px;
  background: #fff;
  border-radius: 20px;
  padding: 60px;
  & > h3 {
    font-weight: bold;
    font-size: 40px;
    line-height: 160%;
    letter-spacing: 0.05em;
    color: $colorNavy;
  }
  & > p {
    margin-top: 32px;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.05em;
  }
  @include mq(u-pc) {
    border-radius: 8px 0px 0px 8px;
    padding: 20px;
    & > h3 {
      font-size: 20px;
    }
    & > p {
      font-size: 16px;
      margin-top: 12px;
    }
  }
}

.p-blog__section {
  margin-top: 160px;
  @include mq(u-sp) {
    margin-top: 80px;
  }
}

.p-blog__section__inner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  @include mq(u-pc) {
    padding: 0 15px;
  }
  @include mq(u-sp) {
    padding: 0 15px;
  }
}

.p-blog__section__heading {
  text-align: center;
  & > h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: 0.1em;
    color: $colorTurquoise;
  }
  & > p {
    margin-top: 20px;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.1em;
    color: $colorNavy;
    font-family: "ubuntu";
  }
  @include mq(u-pc) {
    & > h2 {
      font-size: 28px;
    }
    & > p {
      margin-top: 16px;
      font-size: 12px;
    }
  }
}

.p-blog__section__btn__wrap {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.p-blog__section__btn {
  display: inline-block;
  background-color: $colorOrange;
  border-radius: 40px;
  padding: 16px 20px;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  & > span {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: #fff;
    padding-right: 30px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 24px;
      height: 24px;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='none' height='24' viewBox='0 0 25 24' width='25' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.5 12c0-5.52285 4.47715-10 10-10 5.5228 0 10 4.47715 10 10 0 5.5228-4.4772 10-10 10-5.52285 0-10-4.4772-10-10z' fill='%23fff'/%3E%3Cg clip-rule='evenodd' fill-rule='evenodd'%3E%3Cpath d='m1.80005 11.9998c0-5.90944 4.79055-10.7 10.69995-10.7 5.9095 0 10.7001 4.79056 10.7001 10.7 0 5.9095-4.7906 10.7-10.7001 10.7-5.9094 0-10.69995-4.7905-10.69995-10.7zm10.69995-9.3c-5.1362 0-9.29995 4.16376-9.29995 9.3 0 5.1363 4.16375 9.3 9.29995 9.3 5.1363 0 9.3-4.1637 9.3-9.3 0-5.13624-4.1637-9.3-9.3-9.3z' fill='%23fff'/%3E%3Cpath d='m10.9797 8.03154c.2587-.28736.7013-.31066.9886-.05204l-.4682.52031-.4683.52031c-.2874-.25862-.3107-.70123-.0521-.98858zm.5204 7.46826-.4683-.5203.0383-.0346.11-.0996c.095-.0861.2308-.2097.3938-.3592.3264-.2991.7606-.7008 1.194-1.1125.4352-.4134.8625-.8304 1.1784-1.162.0741-.0779.1399-.1487.1967-.2118-.0568-.0631-.1226-.1339-.1967-.2118-.3159-.3316-.7432-.7486-1.1784-1.1619-.4334-.4118-.8676-.81347-1.194-1.11263-.163-.14945-.2988-.27304-.3938-.35916l-.11-.09959-.0383-.0346.4683-.52031.4682-.52031.0403.03633.1122.10156c.0964.0875.2341.21274.3991.36407.3299.30241.7707.71012 1.2123 1.1296.4399.41784.8875.85404 1.2279 1.21144.1687.1772.3217.346.4362.4891.0562.0703.1149.1489.1631.2284.024.0397.0535.0927.0787.1544.0214.0525.062.1635.062.3054s-.0406.2529-.062.3054c-.0252.0618-.0547.1147-.0787.1544-.0482.0795-.1069.1581-.1631.2284-.1145.1431-.2675.3119-.4362.4891-.3404.3574-.788.7936-1.2279 1.2115-.4416.4194-.8824.8272-1.2123 1.1296-.165.1513-.3027.2765-.3991.364l-.1122.1016-.0395.0356zm0 0-.4683-.5203c-.2874.2586-.3107.7012-.0521.9886.2587.2873.7013.3106.9886.052z' fill='%23e85900'/%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
    }
  }
}

.p-blog__section__articles {
  display: flex;
  gap: 40px;
  margin-top: 60px;
  @include mq(u-pc) {
    gap: 20px;
  }
  @include mq(u-sp) {
    flex-direction: column;
    margin-top: 40px;
  }
}

.p-blog__section__card {
  width: calc(33.3% - 26px);
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  transition: filter 0.2s;
  &:hover {
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
  }
  & > figure {
    width: 100%;
    aspect-ratio: 2/1;
    overflow: hidden;
  }
  @include mq(u-pc) {
    width: calc(33.3% - 13px);
  }
  @include mq(u-sp) {
    width: 100%;
  }
}

.top-seo-contents {
  margin: 160px auto 0;
  @include mq(u-sp) {
    margin: 100px auto 0;
  }
}

.top-seo-contents .top-seo-contents-inner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  @include mq(u-sp) {
    padding: 0 15px;
  }
}

.top-seo-contents h2 {
  font-weight: 700;
  font-size: 38px;
  line-height: 100%;
  letter-spacing: .1em;
  color: #1e307e;
  text-align: center;
  line-height: 1.6;
  margin-bottom: 30px;
  @include mq(u-sp) {
    font-size: 28px;
  }
}

.top-seo-contents h3 {
  margin-top: 70px;
  margin-bottom: 30px;
  font-weight: 700;
  padding-bottom: 8px;
  border-bottom: 4px solid #34aaae;color:#1e307e;
  font-size: 30px;
  @include mq(u-sp) {
    font-size: 20px;
  }
}

.top-seo-contents h4 {
  margin-top: 50px;
  margin-bottom: 15px;
  font-weight: 700;
  color: #1e307e;font-size:22px;
  padding: 10px 0 10px 10px;
  border-left: 2px solid #34aaae;
  @include mq(u-sp) {
    font-size: 18px;
  }
}

.top-seo-contents p {
  margin-bottom:30px
}

// よくあるご質問
.p-faq__section {
  margin-top: 160px;
  @include mq(u-pc) {
    margin-top: 94px;
  }
}

.p-faq__section__inner {
  @include mq(u-pc) {
    padding: 80px 15px;
  }
}

.p-faq__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.p-faq__item {
  padding: 40px 60px;
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  max-width: 984px;
  margin: 0 auto;
  @include mq(u-pc) {
    max-width: unset;
    padding: 20px;
  }
}

.p-faq__question-inner {
  display: flex;
  &::before {
    width: 20px;
    content: "Q";
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #1e307e;
    margin-right: 20px;
  }
  @include mq(u-pc) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.p-faq__question-text {
  font-size: 18px;
  font-weight: bold;
  color: #1e307e;
  line-height: 1.6;
  padding-top: 5px;
  @include mq(u-pc) {
    padding: 0;
  }
}

.p-faq__answer-inner {
  display: flex;
  margin-top: 12px;
  &::before {
    width: 20px;
    content: "A";
    flex-shrink: 0;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #34aaae;
    margin-right: 20px;
  }
  @include mq(u-pc){
    flex-direction: column;
  }
}

.p-faq__answer-text {
  line-height: 1.6;
  padding-top: 5px;
  @include mq(u-pc) {
    padding: 0;
  }
}

.p-top__content .p-content {
 margin-top: 160px; 
 @include mq(u-sp) {
  margin-top: 80px;
 }
}

.p-top__content .p-page__content__wrap {
  max-width: 1680px;
  padding: 0 40px;
  @include mq(u-pc) {
    padding: 0;
  }
}

.p-top__content .p-info__list__item {
  max-width: 824px;
  margin: 0 auto;
}