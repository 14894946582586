.p-sp-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  &.is-fade {
    display: none;
  }
}

.p-sp-banner-inner {
  display: flex;
  padding: 2px;
  justify-content: space-between;
}

.p-sp-banner-order-net,
.p-sp-banner-order-tel {
  width: calc(50% - 1px);
  border-radius: 6px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-sp-banner-order-tel-wide {
  width: 100%;
  border-radius: 6px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
}

.p-sp-banner-order-net {
  background-color: #06C755;
  & .p-sp-banner-order-label::before {
    background-image: url("../images/icon/img_icon_reserve_line.png");
  }
}
.p-sp-banner-order-tel,
.p-sp-banner-order-tel-wide {
  background-color: $colorOrange;
  & .p-sp-banner-order-label::before {
    background-image: url("../images/icon/img_icon_tel.png");
  }
}

.p-sp-banner-icon {
  width: 24px;
  margin: 0 auto;
}

.p-sp-banner-order-label {
  text-align: center;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  padding: 6px 0 6px 32px;
  position: relative;
  display: inline-block;
  font-weight: bold;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-size: cover;
  }
}

.p-sp-banner-order-label-wide {
  text-align: center;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.05em;
  margin-top: 4px;
  color: #ffffff;
  padding: 6px 0 6px 36px;
  position: relative;
  display: inline-block;
  font-weight: bold;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url("../images/icon/img_icon_tel.png");
    background-size: cover;
  }
}

.p-sp-banner-subtext {
  margin-top: 2px;
  font-size: 10px;
  text-align: center;
  color: #fff;
}
