.u-hidden {
  display: none !important;
}
.u-hidden-sp {
  @include mq(u-sp) {
    display: none !important;
  }
}
.u-hidden-sp-over {
  @include mq(o-sp) {
    display: none !important;
  }
}
.u-hidden-tab {
  @include mq(u-pc) {
    display: none !important;
  }
}
.u-hidden-pc {
  @include mq(o-pc) {
    display: none !important;
  }
}
