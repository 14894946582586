.p-footer__inner {
  padding: 24px 80px;
  display: flex;
  justify-content: flex-end;
  gap: 60px;
  align-items: center;
  @include mq(u-pc) {
    display: block;
    text-align: center;
    padding: 14px 0;
  }
}

.p-footer__list {
  display: flex;
  gap: 60px;
  justify-content: end;
}

.p-footer__list__item {
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #fff;
  & span {
    padding-right: 20px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      background-image: url("../images/icon/img_icon_link.png");
      background-size: cover;
      width: 10px;
      height: 10px;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.p-footer__copy {
  color: #fff;
  font-size: 12px;
  line-height: 100%;
  @include mq(u-pc) {
    font-size: 10px;
    letter-spacing: 0.05em;
  }
}
