// breakpoint
$breakSP: 767;
$breakPC: 994;
$breakpoints: (
  "u-sp": (
    max-width: #{$breakSP}px,
  ),
  "o-sp": (
    min-width: #{$breakSP + 1}px,
  ),
  // "sp-pc":(max-width: #{$breakPC}px) and (min-width: #{$breakSP + 1}px),
  "u-pc":
    (
      max-width: #{$breakPC}px,
    ),
  "o-pc": (
    min-width: #{$breakPC + 1}px,
  ),
) !default;
@mixin mq($breakpoint) {
  @media #{inspect(map-get($breakpoints, $breakpoint))} {
    @content;
  }
}

// ie用
@mixin ie {
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}

// PATH
$bgPath: "/assets/images/";

// MainColor
$colorNavy: #1e307e;
$colorTurquoise: #34aaae;
$colorOrange: #e65e09;
$colorYellow: #f4c01e;