.p-header__inner {
  padding: 8px 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > nav {
    display: inline-block;
  }
  @include mq(u-pc) {
    width: 172px;
    padding: 6px 40px;
  }
  @media screen and (max-width: 1140px) {
    display: none;
  }
}

.p-header__logo {
  display: inline-block;
  width: 204px;
  @media screen and (max-width: 1140px) {
    width: 136px;
    padding-bottom: 0;
  }
  @include mq(u-sp) {
    width: 102px;
  }
}

.p-header__list {
  display: flex;
  gap: 40px;
}

.p-header__list__item {
  & > a {
    position: relative;
    display: inline-block;
    padding: 25px 0 26px;
    position: relative;
    font-weight: bold;
    font-size: 16px;
    color: $colorOrange;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 2px;
      background: $colorYellow;
      transform: scale(0, 1);
      transform-origin: right top;
      transition: transform 0.3s;
    }
    &:hover:after {
      transform-origin: left top;
      transform: scale(1, 1);
    }
  }
  &.is-current {
    & > a:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 2px;
      transform: scale(1, 1);
      background: $colorYellow;
    }
  }
}

.p-header__links {
  display: flex;
  align-items: center;
  gap: 40px;
  padding-bottom: 4px;
}

.header__sns__wrap {
  display: flex;
  gap: 24px;
  @media screen and (max-width: 1140px) {
    gap: 40px;
  }
}

.p-header__sns {
  display: inline-block;
  width: 18px;
  @media screen and (max-width: 1140px) {
    width: 20px;
  }
}

.p-header__tel {
  & > address p + p {
    text-align: center;
    margin-top: 6px;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
  }
  & .p-inquiry__tel {
    padding-left: 32px;
    &::after {
      width: 24px;
      height: 24px;
    }
  }
}

.p-header__inner__sp {
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media screen and (min-width: 1141px) {
    display: none;
  }
  @include mq(u-sp) {
    padding: 4px 8px;
    min-height: 46px;
  }
}

.p-header__sp__content {
  display: flex;
  align-items: center;
  gap: 24px;
  & > p {
    font-size: 10px;
    line-height: 140%;
    letter-spacing: 0.05em;
  }
}

.p-header__hamburger {
  position: absolute;
  right: 20px;
  z-index: 4;
  display: inline-block;
  padding: 8px 12px 22px;
  &::after {
    position: absolute;
    content: "メニュー";
    font-size: 10px;
    width: 100%;
    left: 0;
    bottom: 4px;
  }
  & > div {
    width: 20px;
    height: 16px;
    position: relative;
    & > span {
      position: absolute;
      width: 20px;
      height: 1px;
      background-color: #252732;
      transition: 0.2s;
      &:first-child {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }
      &:last-child {
        bottom: 0;
      }
    }
  }
  &.is-open {
    &::after {
      content: "閉じる";
      text-align: center;
      color: $colorNavy;
    }
    & > div {
      & > span {
        background-color: $colorNavy;
        &:first-child {
          transform: rotate(45deg) translateY(-50%);
          top: 50%;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          bottom: 0;
          transform: rotate(-45deg) translateY(-50%);
          top: 50%;
        }
      }
    }
  }
  @include mq(u-sp) {
    right: 8px;
    padding: 8px 12px 16px;
    &::after {
      bottom: 0px;
    }
  }
}

.p-modal__menu {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: #FFF89F;
  right: -100%;
  transition: right 0.2s;
  &.is-open {
    right: 0;
  }
}

.p-modal__menu__inner {
  padding: 60px 13vw;
}

.p-modal__list__item {
  & a {
    display: block;
    padding: 2.4vh 0;
    font-weight: bold;
    font-size: 6vw;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $colorOrange;
  }
}

.p-modal__list__sub {
  margin-top: 30px;
}

.p-modal__list__sub__item {
  & a {
    display: block;
    padding: 1.5vh 0;
    font-weight: bold;
    font-size: 3.2vw;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $colorOrange;

    & span {
      position: relative;
      padding-right: 3vw;
      &:after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        content: "";
        width: 2vw;
        height: 2vw;
        min-width: 6px;
        min-height: 6px;
        background-image: url("../images/icon/img_icon_link_navy.png");
        background-size: cover;
      }
    }
  }
}

.p-modal__sns {
  margin-top: 30px;
}
