.p-blog__sorry {
  background-color: #fff;
  border-radius: 40px;
  width: 100%;
  max-width: 984px;
  margin: 0 auto;
  padding: 80px 180px;
  display: flex;
  justify-content: space-between;
  @include mq(u-pc) {
    padding: 80px;
  }
  @include mq(u-sp) {
    padding: 40px 26px;
    flex-direction: column;
    text-align: center;
  }
}

.p-blog__sorry__text {
  & > h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $colorTurquoise;
  }
  & > p {
    margin-top: 40px;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.05em;
  }
}

.p-blog__sorry__img {
  width: 260px;
  @include mq(u-sp) {
    width: 100%;
    margin-top: 40px;
  }
}

.p-blog__contents {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 60px 26px;
  &.load {
    padding: 0 40px;
    @include mq(u-pc) {
      padding: 0 15px;
    }
  }
  @include mq(u-sp) {
    gap: 20px;
  }
}

.p-blog__contents__wrap {
  margin: 80px auto 0;
  padding: 0 40px;
  & > p {
    color: $colorTurquoise;
    max-width: 1320px;
    width: 100%;
    margin: 0 auto 40px;
    font-weight: bold;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.05em;
  }
  @include mq(u-pc) {
    padding: 0 15px;
    & > p {
      margin: 0 auto 30px;
    }
  }
}

.p-blog__contents__item {
  display: inline-block;
  overflow: hidden;
  border-radius: 8px;
  width: calc(33.3% - 17.3px);
  background-color: #fff;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  & > figure {
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
  }
  @include mq(u-pc) {
    transition: unset;
    width: calc(50% - 13px);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  @include mq(u-sp) {
    width: 100%;
  }
}

.p-blog__contents__item__info {
  padding: 20px 12px;
}

.p-blog__contents__item__date {
  font-weight: bold;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: #888;
}

.p-blog__taxonomy__wrap {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.p-blog__taxonomy--category,
.p-taxonomy--menu {
  border: 1px solid $colorOrange;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.05em;
  display: inline-block;
}

.p-blog__taxonomy--category {
  color: #fff;
  background-color: $colorOrange;
}

.p-taxonomy--menu {
  color: $colorOrange;
  background-color: #fff;
}

.p-blog__contents__item__title {
  margin-top: 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.05em;
  display: inline-block;
  width: 100%;
}

.p-blog__more__btn {
  display: block;
  width: 100%;
  max-width: 344px;
  margin: 100px auto 0;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $colorNavy;
  cursor: pointer;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  @include mq(u-pc) {
    transition: unset;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
}

.p-blog__single__fv {
  max-width: 984px;
  margin: 0 auto;
  padding-top: 20px;
}

.p-blog__info {
  display: flex;
  gap: 40px;
}

.p-blog__info__date {
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: #888888;
}

.p-content__taxonomy__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.p-content__heading {
  margin-top: 20px;
  font-weight: bold;
  font-size: 28px;
  line-height: 160%;
  letter-spacing: 0.05em;
  & + .p-menu__price__wrap > .p-menu__discount {
    justify-content: flex-end;
  }
}

.p-content__article {
  margin-top: 120px;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
  & *:first-child {
    margin-top: 0;
  }
  & h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0.05em;
    margin-top: 80px;
    padding: 12px 12px 12px 20px;
    background: rgba(52, 170, 174, 0.1);
    border-left: 8px solid $colorTurquoise;
  }
  & h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.05em;
    margin-top: 60px;
    padding-bottom: 8px;
    border-bottom: 4px solid #34aaae;
  }
  & h4 {
    font-weight: bold;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.05em;
    margin-top: 60px;
    padding-bottom: 8px;
  }
  & a {
    font-weight: bold;
    color: $colorTurquoise;
    margin-top: 20px;
  }
  & p {
    margin-top: 20px;
  }
  & ul {
    margin-top: 20px;
    padding-left: 32px;
    @include mq(u-pc) {
      padding-left: 16px;
    }
  }
  & ol {
    margin-top: 20px;
    padding-left: 50px;
    @include mq(u-pc) {
      padding-left: 42px;
    }
    & li {
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.05em;
      margin-top: 12px;
      padding-left: 0;
      position: relative;
      list-style-type: decimal;
      &::before {
        content: none;
      }
    }
  }
  & li {
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.05em;
    margin-top: 12px;
    padding-left: 30px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      background-color: #c4c4c4;
      border-radius: 50%;
      top: 9px;
      left: 0;
    }
  }
  & em {
    background: linear-gradient(transparent 60%, rgba(1255, 242, 128, 1) 40%);
  }
  & strong {
    font-weight: bold;
  }
  @include mq(u-pc) {
    margin-top: 60px;
    & h2 {
      margin-top: 40px;
    }
    & h3,
    & h4 {
      margin-top: 30px;
    }
  }
}

.p-controller {
  background-color: #fff;
  padding: 12px 0;
  @include mq(u-pc) {
    padding: 12px 0 0;
    width: 100%;
    overflow: scroll;
  }
}

.p-controller__inner {
  display: flex;
  gap: 80px;
  justify-content: center;
  @include mq(u-pc) {
    gap: 40px;
    border-bottom: 2px solid #e7e7e7;
  }
  @include mq(u-sp) {
    gap: 20px;
  }
}

.p-controller__btn {
  background-color: #fff;
  color: #888;
  border-radius: 40px;
  padding: 12px 20px;
  @include mq(o-pc) {
    transition: box-shadow 0.2s;
    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    }
    &.is-active {
      background-color: $colorOrange;
      color: #fff;
    }
  }
  @include mq(u-pc) {
    border-radius: 0;
    padding: 0 4px 12px;
    color: #888;
    &.is-active {
      color: $colorOrange;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background-color: $colorOrange;
        bottom: -2px;
        left: 0;
      }
    }
  }
}

.p-controller__label {
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
}

.p-blog__menu__controller {
  padding: 20px 0;
  @include mq(u-pc) {
    padding-top: 20px;
  }
}

.p-blog__menu__controller__inner {
  display: flex;
  justify-content: center;
  @include mq(u-sp) {
    flex-wrap: wrap;
    gap: 20px 0px;
  }
}

.p-blog__menu__controller__btn {
  width: 120px;
  @include mq(u-pc) {
    min-width: 94px;
  }
  @include mq(u-sp) {
    width: calc(20% - 20px);
    min-width: 84px;
  }
  &.is-active {
    color: $colorTurquoise;
    & .p-blog__menu__controller__btn__icon {
      border: 2px solid $colorTurquoise;
      box-sizing: border-box;
      padding: 2px;
    }
  }
}

.p-blog__menu__controller__btn__icon {
  width: 56px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}

.p-blog__menu__controller__label {
  margin-top: 12px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  width: 100%;
  @include mq(u-pc) {
    font-size: 12px;
  }
  @include mq(u-sp) {
    font-size: 10px;
    margin-top: 8px;
  }
}
