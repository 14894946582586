// .p-page {
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
// }
.l-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.swiper {
  display: none;
}

.swiper.swiper-initialized {
  display: block; /*swiper-initializedが付与されたら表示*/
}