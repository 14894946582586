.p-page {
  // header分
  padding-top: 82px;

  @media screen and (max-width: 1140px) {
    padding-top: 62px;
  }
  @include mq(u-sp) {
    padding-top: 46px;
  }
}

.p-page__fv {
  width: 100%;
  padding: 40px;
  background-color: #fff;
  @include mq(u-pc) {
    padding: 15px;
  }
}

.p-page__fv__inner {
  max-width: 1682px;
  margin: 0 auto;
  border-radius: 40px;
  text-align: center;
  padding: 100px 0;
  // debug
  background-color: burlywood;
  background-size: 1682px;
  background-position: center;
  //bg-img
  &[data-type="blog"] {
    background-image: url("../images/page/fv/img_fv_blog.jpg");
  }
  &[data-type="about"] {
    background-image: url("../images/page/fv/img_fv_about.jpg");
  }
  &[data-type="menu"] {
    background-image: url("../images/page/fv/img_fv_menu.jpg");
  }
  &[data-type="contact"] {
    background-image: url("../images/page/fv/img_fv_contact.jpg");
  }
  @include mq(u-pc) {
    padding: 60px 0;
    &[data-type="menu"] {
      background-position-x: right;
    }
    &[data-type="blog"] {
      background-size: cover;
      background-image: url("../images/page/fv/img_fv_blog_sp.jpg");
    }
  }
}

.p-page__fv__translate {
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $colorTurquoise;
  font-family: "ubuntu";
  @include mq(u-pc) {
    font-size: 12px;
  }
}

.p-page__fv__heading {
  margin-top: 20px;
  font-weight: bold;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: $colorOrange;
  @include mq(u-pc) {
    font-size: 20px;
    margin-top: 16px;
  }
}

.p-page__content__wrap {
  max-width: 1064px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  & + .p-page__content__wrap {
    margin-top: 40px;
  }
  @include mq(u-pc) {
    padding: 0;
  }
}

// パンくずリスト
.p-breadcrumb {
  width: 984px;
  padding: 20px 20px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  @include mq(u-pc) {
    width: 100%;
    gap: 16px;
    padding: 20px 16px;
  }
}

.p-breadcrumb__item {
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.05em;
  position: relative;
  flex-shrink: 0;
  transition: color 0.2s;
  &:first-child {
    padding-left: 20px;
    &::after {
      position: absolute;
      content: "";
      background-image: url("../images/icon/img_icon_home.png");
      background-size: contain;
      width: 14px;
      height: 14px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &:not(:first-child) {
    padding-left: 20px;
    &::after {
      position: absolute;
      content: "/";
      color: #252732;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    @include mq(u-pc) {
      padding-left: 16px;
    }
  }
  &:last-child {
    // color: $colorTurquoise;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-shrink: 1;
    & > a {
      display: inline;
    }
  }
  & > a {
    display: inline-block;
  }
  &:hover {
    color: $colorTurquoise;
    &:first-child {
      &::after {
        background-image: url("../images/icon/img_icon_home_hover.svg");
      }
    }
  }
}

//固定ページ
.p-fixed__wrap {
  width: 984px;
  margin: 60px auto 0;
  @include mq(u-pc) {
    width: 100%;
    margin: 0 auto;
  }
}

.p-content {
  background-color: #fff;
  border-radius: 40px;
  padding: 80px 60px;
  @include mq(u-pc) {
    border-radius: 0;
    padding: 40px 15px;
  }
}

.p-fixed__btn__wrap {
  margin-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  @include mq(u-sp) {
    padding: 0 15px;
  }
}

.p-fixed__btn {
  display: inline-block;
  width: 100%;
  max-width: 345px;
  padding: 16px 0;
  border-radius: 40px;
  background-color: $colorNavy;
  color: #fff;
  text-align: center;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  & > span {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }
}

.p-policy__senders {
  margin-top: 60px;
  text-align: end;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
}

.p-policy__content {
  margin-top: 60px;
}

.p-policy__text {
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
}

.p-policy__heading {
  margin-top: 60px;
  font-weight: bold;
  font-size: 22px;
  line-height: 160%;
}

.p-policy__description {
  margin-top: 16px;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
}

.p-policy__list {
  margin-top: 16px;
  padding-left: 12px;
}

.p-policy__list__item {
  padding-left: 18px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    background-color: #c4c4c4;
    border-radius: 50%;
    top: 9px;
    left: 0;
  }
  &:not(:first-child) {
    margin-top: 12px;
  }
}
//情報リスト

.p-info__list__item {
  display: flex;
  justify-content: space-between;
  padding: 24px 60px;
  border-top: 1px solid #ddd;
  &:last-child {
    border-bottom: 1px solid #ddd;
  }
  &.column {
    flex-direction: column;
    & .p-info__list__description {
      width: 100%;
      margin-top: 20px;
      padding-left: 40px;
    }
  }
  @include mq(u-pc) {
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
    &.column .p-info__list__description {
      width: 100%;
      margin-top: 0;
      padding-left: 0;
    }
  }
}

.p-info__list__title {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.05em;
}

.p-info__list__description {
  width: 544px;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
  & > a {
    color: $colorTurquoise;
  }
  & > ul {
    padding-left: 12px;
    & > li {
      padding-left: 18px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: #c4c4c4;
        border-radius: 50%;
        top: 9px;
        left: 0;
      }
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
  @include mq(u-pc) {
    width: 100%;
  }
}

.p-area__list__item {
  display: flex;
  gap: 16px;
  &:not(:first-child) {
    margin-top: 20px;
  }
  @include mq(u-pc) {
    gap: 8px;
    flex-direction: column;
  }
}

.p-area__list__title {
  font-size: 16px;
  letter-spacing: 0.05em;
  flex-shrink: 0;
}

.p-area__list__name {
  width: 100%;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: #868686;
}

// エラー系
.p-error__img {
  width: 100%;
  max-width: 400px;
  margin: 20px auto 0;
}

.p-error__heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  margin-top: 40px;
}

.p-error__description {
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.05em;
  margin-top: 32px;
}

//ページ下部お問い合わせセクション
.p-inquiry {
  margin: 160px auto 0;
  width: 760px;
  @include mq(u-sp) {
    width: 100%;
    padding: 0 15px;
    margin: 100px auto 0;
  }
}

.p-inquiry__caption {
  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-align: center;
  color: $colorOrange;
  @include mq(u-pc) {
    font-size: 20px;
  }
}

.p-inquiry__tel {
  padding-left: 36px;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $colorOrange;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    background-image: url("../images/icon/img_icon_call.png");
    background-size: contain;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.p-inquiry__btn__wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  @include mq(u-sp) {
    flex-direction: column-reverse;
    gap: 0;
  }
}

.p-inquiry__btn {
  display: inline-block;
  width: 345px;
  padding: 20px 0;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  @include mq(u-pc) {
    transition: unset;
    flex-direction: column;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  & > span {
    padding-left: 36px;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.05em;
    color: #ffffff;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 24px;
      height: 24px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &[data-icon="reserve"] {
    background-color: #06C755;
    flex-direction: column;
    & > span::before {
      background-image: url("../images/icon/img_icon_reserve_line.png");
      background-size: contain;
    }
    & p {
      font-size: 12px;
      line-height: 100%;
      margin-top: 8px;
    }
    @include mq(u-sp) {
      margin-top: 20px;
    }
  }
  &[data-icon="tel-wide"] {
    background-color: $colorOrange;
    width: 100%;
    & > address {
      text-align: center;
      font-size: 12px;
      letter-spacing: 0.05em;
      & > .p-inquiry__tel {
        color: #fff;
        &::after {
          background-image: url("../images/icon/img_icon_tel.png");
        }
        & + p {
          font-size: 12px;
          line-height: 100%;
          letter-spacing: 0.05em;
          margin-top: 8px;
        }
      }
    }
  }
  &[data-icon="tel"] {
    background-color: $colorOrange;
    & > address {
      text-align: center;
      font-size: 12px;
      letter-spacing: 0.05em;
      color: #fff;
      & > .p-inquiry__tel {
        color: #fff;
        &::after {
          background-image: url("../images/icon/img_icon_tel.png");
        }
        & + p {
          font-size: 12px;
          line-height: 100%;
          letter-spacing: 0.05em;
          margin-top: 8px;
        }
      }
    }
  }
  @include mq(u-sp) {
    width: 100%;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    padding: 16px 0;
    &[data-icon="tel-sp"] {
      background-color: $colorOrange;
      & > span::before {
        background-image: url("../images/icon/img_icon_tel.png");
        background-size: contain;
      }
      p {
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.05em;
        margin-top: 8px;
      }
    }
  }
}

.p-content__share {
  margin-top: 160px;
  display: flex;
  gap: 60px;
  justify-content: center;
  & > a {
    display: inline-block;
    width: 24px;
  }
  & > p {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.05em;
    padding-left: 50px;
    position: relative;
    color: $colorOrange;
    &::before {
      position: absolute;
      content: "";
      width: 34px;
      height: 24px;
      left: 0;
      background-image: url("../images/icon/img_icon_share.png");
      background-size: contain;
    }
  }
  @include mq(u-pc) {
    margin-top: 50px;
    gap: 30px;
    & > p {
      font-size: 12px;
    }
  }
}

.p-single__controller {
  max-width: 824px;
  margin: 80px auto 0;
}

.p-single__controller__inner {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
  @include mq(u-pc) {
    padding: 0 15px 20px 15px;
  }
}

.p-single__controller__prev,
.p-single__controller__next {
  font-weight: bold;
  font-size: 14px;
  color: $colorNavy;
  position: relative;
  margin-bottom: 24px;
}

.p-single__controller__prev {
  padding: 10px 0 10px 48px;
  &::before {
    position: absolute;
    content: "";
    width: 32px;
    height: 32px;
    background-size: cover;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../images/icon/img_icon_prev.png");
  }
}

.p-single__controller__next {
  padding: 10px 48px 10px 0;
  &::after {
    position: absolute;
    content: "";
    width: 32px;
    height: 32px;
    background-size: cover;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../images/icon/img_icon_next.png");
  }
}

.p-single__controller__list {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  & > i {
    display: block;
    width: 32px;
    height: 32px;
  }
  & > p {
    font-weight: bold;
    font-size: 14px;
    color: $colorNavy;
    position: relative;
  }
}

.p-fv-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 70px;
  padding-top: 40px;
  @include mq(u-pc) {
    padding-bottom: 60px;
  }
}

.swiper-slide.p-slider__item {
  max-width: 890px;
  max-height: 500px;
  overflow: hidden;
  border-radius: 20px;
  transition: box-shadow 0.2s;
  @include mq(u-pc) {
    max-height: 46vw;
  }
}

.p-slider__controller {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  padding-bottom: 12px;
}
.p-slider__controller__btn {
  width: 32px;
  cursor: pointer;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
}

.p-slider__controller__dot {
  display: flex;
  gap: 40px;
  & > .swiper-pagination-bullet {
    border: 1px solid $colorTurquoise;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    &.swiper-pagination-bullet-active {
      background-color: $colorTurquoise;
    }
  }
  @include mq(u-pc) {
    gap: 16px;
    & > .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
    }
  }
}

.p-discount__tag {
  color: #fff;
  background-color: #ff5151;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  display: inline-block;
}
