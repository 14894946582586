*,
*::after,
*::before {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

dt,
dd {
  word-break: break-all;
}

body {
  background-color: #f4f7f6;
  color: #252732;
}

.grecaptcha-badge {
  display: none;
}
/* デバッグ用 */
// body * {
//   outline: 1px dotted rgba(255, 0, 0, 0.2) !important;
// }
// body * * {
//   outline: 1px dotted rgba(0, 128, 0, 0.2) !important;
// }
// body * * * {
//   outline: 1px dotted rgba(255, 165, 0, 0.2) !important;
// }
// body * * * * {
//   outline: 1px dotted rgba(0, 0, 255, 0.2) !important;
// }

// header * {
//   outline: 1px dotted rgba(255, 0, 0, 0.2) !important;
// }
// header * * {
//   outline: 1px dotted rgba(0, 128, 0, 0.2) !important;
// }
// header * * * {
//   outline: 1px dotted rgba(255, 165, 0, 0.2) !important;
// }
// header * * * * {
//   outline: 1px dotted rgba(0, 0, 255, 0.2) !important;
// }
