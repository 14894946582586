.p-contact__description {
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.05em;
  padding-top: 60px;
  @include mq(u-pc) {
    padding: 20px 15px;
  }
}
.p-contact__inquiry__wrap {
  margin-top: 40px;

  @include mq(u-pc) {
    margin-top: 20px;
    padding: 0 15px;
  }
}
.p-contact__inquiry {
  padding: 20px 0;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  & > address {
    margin-top: 16px;
    & > p {
      margin-top: 12px;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0.05em;
    }
  }
}

.p-contact__inquiry__guide {
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.05em;
}

.p-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  @include mq(u-pc) {
    gap: 30px;
  }
}

.p-form__item {
  display: flex;
  gap: 60px;
  align-items: center;
  &--textarea {
    display: flex;
    gap: 60px;
  }
  @include mq(u-pc) {
    flex-direction: column;
    gap: 10px;
    &--textarea {
      flex-direction: column;
      gap: 10px;
    }
  }
}

.p-form__item__label {
  display: inline-block;
  width: 204px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.05em;
  position: relative;
  flex-shrink: 0;
  &::after {
    content: "必須";
    position: absolute;
    top: 12px;
    right: 0;
    transform: translateY(-50%);
    background-color: #ff5151;
    border-radius: 2px;
    padding: 4px 6px;
    color: #fff;
    font-size: 10px;
    letter-spacing: 0.05em;
  }
  @include mq(u-pc) {
    display: inline;
    width: auto;
    padding-right: 50px;
    margin-right: auto;
  }
}

.p-form__item__input {
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 16px 18px;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  width: 345px;
  &::placeholder {
    color: #aeaeae;
  }
  @include mq(u-pc) {
    width: 100%;
  }
}

.p-form__item__textarea {
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 16px 18px;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  height: 148px;
  width: 618px;
  &::placeholder {
    color: #aeaeae;
  }
  @include mq(u-pc) {
    width: 100%;
  }
}

.wpcf7-form-control.has-spinner.wpcf7-submit {
  cursor: pointer;
  margin: 0 auto;
  background: $colorOrange;
  border-radius: 20px;
  padding: 12px 72px;
  font-weight: bold;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: #ffffff;
  &:disabled {
    background: #f2f2f2;
  }
}

.p-form__item--consent + p {
  text-align: center;
}

.p-form__item--consent {
  text-align: center;
  & .wpcf7-list-item {
    margin: 0 !important;
  }
  & .wpcf7-list-item-label {
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.05em;
    position: relative;
    padding-left: 36px;
    display: inline-block;
    & > a {
      color: #34aaae;
    }
    &::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 4px;
      background-color: #aeaeae;
    }
    &::after {
      content: "";
      position: absolute;
      width: 15px;
      height: 11px;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("../images/icon/img_icon_check.png");
      background-size: cover;
    }
  }
}
input[type="checkbox"]:checked + .wpcf7-list-item-label:before {
  background-color: $colorOrange;
}

.wpcf7-response-output,
.wpcf7-spinner {
  display: none !important;
}

.wpcf7-not-valid-tip {
  font-size: 12px;
  line-height: 100%;
  color: #ff5151;
  margin-top: 8px;
}

.wpcf7-not-valid {
  border: 1px solid #ff5151;
  box-sizing: border-box;
}
.p-form__item .wpcf7-list-item-label {
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  padding-left: 34px;
  position: relative;
  cursor: pointer;
  &::before {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #252732;
    box-sizing: border-box;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    left: 6px;
    top: 50%;
    border-radius: 50%;
    background-color: #c4c4c4;
    transform: translateY(-50%);
  }
}

input[type="radio"]:checked + .wpcf7-list-item-label::after {
  background-color: $colorOrange;
}

@include mq(u-pc) {
  .wpcf7-form-control-wrap {
    width: 100%;
  }
}
